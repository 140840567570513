import { Button as MUIButton } from "@mui/material";
import React, { ReactNode } from "react";

type ButtonProps = {
  disabled: boolean;
  onClick: () => void;
  children?: ReactNode;
};

export const Button = (props: ButtonProps) => {
  return (
    <MUIButton
      disabled={props.disabled}
      onClick={props.onClick}
      variant="contained"
      style={{ backgroundColor: "#5B34F4", borderRadius: "15px" }}
    >
      <span className="text-white">{props.children}</span>
    </MUIButton>
  );
};
