import crypto from "crypto";
import { getCookie, setCookie } from "./storage";

/* global console, Buffer */

interface EncryptedPrivateKey {
  iv: string;
  encryptedData: string;
}

/* ENCRYPTING PRIVATE KEY */

async function generatePasswordHash(version: string, SAgent: string, salt: string): Promise<string> {
  const P = `${salt}_${SAgent}_${version}`;
  const iterations = 100000;
  const keyLength = 64;
  const digest = "sha256";

  const hash = await new Promise<string>((resolve, reject) => {
    crypto.pbkdf2(P, salt, iterations, keyLength, digest, (err, derivedKey) => {
      if (err) reject(err);
      resolve(derivedKey.toString("hex"));
    });
  });
  return hash;
}

function encryptPrivateKey(privateKey: string, passwordHash: string) {
  const algorithm = "aes-256-cbc";
  const derivedKey = passwordHash;
  const key = crypto.createHash("sha256").update(derivedKey).digest();
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let encrypted = cipher.update(privateKey, "utf-8", "hex");
  encrypted += cipher.final("hex");
  console.log(encrypted);
  console.log(iv.toString("hex"));
  return {
    iv: iv.toString("hex"),
    encryptedData: encrypted,
  };
}

export async function encryptUserPrivateKey(userPrivateKey: string, SAgent: string, version: string) {
  // Generate a random salt
  const salt = crypto.randomBytes(16).toString("hex");
  // Generate a password hash V_A_S
  const passwordHash = await generatePasswordHash(version, SAgent, salt);
  // encrypt the privake key with the password hash
  const encryptedPrivateKey = encryptPrivateKey(userPrivateKey, passwordHash);
  // expiry time set to 1 hr
  const defaultExpirationTime = 1 * 60 * 60 * 1000;
  const expiration = Date.now() + defaultExpirationTime;
  // set the cookies
  setCookie(`Salt=${salt}; expires=${new Date(expiration).toUTCString()}; path=/`);
  return encryptedPrivateKey;
}

/* DECRYPTING PRIVATE KEY */

function decryptPrivateKey(encryptedPrivateKey: EncryptedPrivateKey, passwordHash: string): any {
  const algorithm = "aes-256-cbc";
  const derivedKey = passwordHash;
  const key = crypto.createHash("sha256").update(derivedKey).digest();
  const iv = Buffer.from(encryptedPrivateKey.iv, "hex");
  const encryptedText = encryptedPrivateKey.encryptedData;

  const decipher = crypto.createDecipheriv(algorithm, key, iv);

  try {
    let decrypted = decipher.update(encryptedText, "hex", "utf-8");
    decrypted += decipher.final("utf-8");
    console.log("Decryption successful:", decrypted);
    return decrypted;
  } catch (error) {
    console.error("Error during decryption:", error);
  }
}

function getSaltFromCookies(): string | null {
  const cookieString = getCookie();
  const cookies = cookieString.split("; ");
  if (!cookies) {
    return null;
  }
  const sessionCookie = cookies.find((cookie) => cookie.startsWith("Salt="));
  return sessionCookie ? sessionCookie.split("=")[1] : null;
}

export async function decryptUserPrivateKey(
  encryptedPrivateKey: EncryptedPrivateKey,
  SAgent: string,
  version: string
): Promise<string | null> {
  // get salt from cookies
  const Salt = getSaltFromCookies();
  if (!Salt) {
    console.error("Session secret not found in cookies.");
    return null;
  }
  // generate the password hash
  const passwordHash = await generatePasswordHash(version, SAgent, Salt);
  // decrypt the encrypted private key with password hash
  const decryptedKey = decryptPrivateKey(encryptedPrivateKey, passwordHash);
  // return the decrypted key
  return decryptedKey;
}
