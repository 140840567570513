import { XOOG_END, XOOG_PATTERN, XOOG_START, XOOG_SUBJECT_RE } from "../../Utils/constants";
import { addAttachment, getAttachmentDataById, removeAttachment, setSubject } from "../office/compose";
import { getBodyText } from "../office/read";
import { b64ToStr, encryptText, strToB64 } from "./crypto";

/* global DOMParser */

// CASES for HTML
export const StringifyEmailContent = (body: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, "text/html");

  // remove attributes
  function removeAttributes(elements: any, attributeName: any) {
    elements.forEach(function (element: any) {
      element.removeAttribute(attributeName);
    });
  }
  removeAttributes(doc.querySelectorAll("[lang]"), "lang");
  removeAttributes(doc.querySelectorAll("[class]"), "class");
  removeAttributes(doc.querySelectorAll("[style]"), "style");
  removeAttributes(doc.querySelectorAll("[name]"), "name");
  removeAttributes(doc.querySelectorAll("[id]"), "id");

  // remove styles
  function removeElementsWithStyle(selector: any, style: any) {
    const elementsToRemove = doc.querySelectorAll(`${selector}[style="${style}"]`);
    elementsToRemove.forEach(function (element) {
      element.parentNode!.removeChild(element);
    });
  }
  removeElementsWithStyle('span[style="mso-ansi-language:EN-US"] > o\\:p', "mso-ansi-language:EN-US");
  removeElementsWithStyle('span[style="mso-ansi-language:EN-US"]', "mso-ansi-language:EN-US");

  // remove span tags without any content
  const spansWithoutContent = doc.querySelectorAll("span:not(:has(*))");
  spansWithoutContent.forEach(function (span) {
    const parent = span.parentNode;
    if (parent) {
      const textContent = parent.textContent!.trim();
      if (!textContent) {
        span.parentNode!.removeChild(span);
      }
    }
  });
  const bodyContent = doc.body.innerHTML;

  // Remove empty p, span, div tags
  const finalString = bodyContent
    .replace(/<o:p>(<\/o:p>|&nbsp;)<\/o:p>|<p><\/p>|<span><\/span>|<div><\/div>/g, "")
    .replace(/<span><span>/g, "<span>")
    .replace(/<\/span><\/span>/g, "</span>")
    .replace(/<div><div>/g, "<div>")
    .replace(/<\/div><\/div>/g, "</div>")
    .replace(/data-editing-info="{&quot;orderedStyleType&quot;:1,&quot;unorderedStyleType&quot;:1}"/g, 'type="disc"');

  return finalString;
};

// Format copied Data
export const formatCopiedData = (subject: string) => {
  return subject
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace("&nbsp;", "")
    .replace(/^\n+|\n+$/g, "")
    .replace(/\n{2,}/g, "\n");
};

// Xoog patter match validation
export const checkMatched = async (selectedText: string) => {
  if (selectedText.includes(XOOG_START)) {
    return true;
  }
  if (selectedText.includes(XOOG_END)) {
    return true;
  }
  let matchFound = false;
  const BodyText = await getBodyText();
  const textMatches = [...BodyText.matchAll(XOOG_PATTERN)];
  if (textMatches) {
    for (const text of textMatches) {
      if (text[0].includes(selectedText)) {
        matchFound = true;
        break;
      }
    }
  }
  return matchFound;
};

export const encryptAttachments = async (
  fileID: string,
  fileName: string,
  receiverPubKey: string,
  senderPrivateKey: string,
  orgID: string,
  pubKey: string
) => {
  if (!fileName.includes(".xoog")) {
    const content = await getAttachmentDataById(fileID);
    const newContent = {
      name: fileName,
      content: content.content,
    };
    const encData = await encryptText(JSON.stringify(newContent), receiverPubKey, senderPrivateKey);
    const encOrgID = strToB64(orgID);
    const encPubKey = strToB64(pubKey);
    const decEncData = b64ToStr(encData);
    const finalData = `${decEncData}|${encOrgID}|${encPubKey}`;
    const endFinalData = strToB64(finalData);
    await addAttachment(endFinalData, fileName + ".xoog");
    await removeAttachment(fileID);
  }
};

export const addSubject = async (subject: string, orgName: string) => {
  const xoog_subject = XOOG_SUBJECT_RE.test(subject);
  if (xoog_subject) {
    const subOrg = XOOG_SUBJECT_RE.exec(subject)![0];
    if (subOrg.trim()) {
      if (subOrg != orgName) {
        const newSubject = subject.replace(XOOG_SUBJECT_RE, ` (Secured by ${orgName} @Xoog)`);
        await setSubject(newSubject);
      }
    }
  } else {
    const newSubject = subject + ` (Secured by ${orgName} @Xoog)`;
    await setSubject(newSubject);
  }
};
