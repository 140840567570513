import { OfficeItem, SelectedDataResponse } from "../../../types";
import {
  ERR_ADD_FILES,
  ERR_FETCH_EMAIL,
  ERR_FILE_SIZE_LIMIT,
  ERR_GET_FILES,
  ERR_GET_SUBJECT,
  ERR_RM_FILES,
  ERR_SET_BODY,
  ERR_SET_SUBJECT,
} from "../../Utils/constants";
/* global Office, Blob */

let item: OfficeItem;

Office.onReady(() => {
  item = Office.context.mailbox.item!;
});

// Get selected content text while composing
export const getSelectedText = (): Promise<SelectedDataResponse> => {
  return new Promise((resolve, reject) => {
    item.getSelectedDataAsync(Office.CoercionType.Text, (result: Office.AsyncResult<SelectedDataResponse>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error("Please select some content and try again"));
      }
    });
  });
};

// Get Selected Content in body while composing
export const getSelectedHTML = (): Promise<SelectedDataResponse> => {
  return new Promise((resolve, reject) => {
    item.getSelectedDataAsync(Office.CoercionType.Html, (result: Office.AsyncResult<SelectedDataResponse>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error("Please select some content and try again"));
      }
    });
  });
};

// Set Selected Content in body while composing
export const setSelectedContent = (newContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    item.setSelectedDataAsync(
      newContent,
      { coercionType: Office.CoercionType.Html },
      (result: Office.AsyncResult<void>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve();
        } else {
          reject(new Error(ERR_SET_BODY));
        }
      }
    );
  });
};

// Get the contents of Subject while compsing
export const getSubject = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    item.subject.getAsync((result: Office.AsyncResult<string>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_GET_SUBJECT));
      }
    });
  });
};

// Set the contents of Subject while composing
export const setSubject = (subject: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    item.subject.setAsync(subject, (result: Office.AsyncResult<void>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve();
      } else {
        reject(new Error(ERR_SET_SUBJECT));
      }
    });
  });
};

// Get the Sender email wile composing
export const getSender = (): Promise<Office.EmailAddressDetails> => {
  return new Promise((resolve, reject) => {
    item.from.getAsync((result: Office.AsyncResult<Office.EmailAddressDetails>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_FETCH_EMAIL));
      }
    });
  });
};

// Get the Recipient Email while composing
export const getReceiver = (): Promise<Office.EmailAddressDetails[]> => {
  return new Promise((resolve, reject) => {
    item.to.getAsync((result: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_FETCH_EMAIL));
      }
    });
  });
};

// Get the attachments while composing
export const getAttachments = (): Promise<Office.AttachmentDetailsCompose[]> => {
  return new Promise((resolve) => {
    item.getAttachmentsAsync(
      { asyncContext: { currentItem: item } },
      (result: Office.AsyncResult<Office.AttachmentDetailsCompose[]>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        }
      }
    );
  });
};

// Get the attachment Data
export const getAttachmentDataById = (attachmentID: string): Promise<Office.AttachmentContent> => {
  return new Promise((resolve, reject) => {
    item.getAttachmentContentAsync(
      attachmentID,
      { asyncContext: null },
      (result: Office.AsyncResult<Office.AttachmentContent>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(new Error(ERR_GET_FILES));
        }
      }
    );
  });
};

// Add Attachment to mail
export const addAttachment = (content: string, filename: string) => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([content]);
    const fileSizeInBytes = blob.size;
    // check the encrypted file size is less than 20 MB
    if (fileSizeInBytes > 20971520) {
      reject(new Error(ERR_FILE_SIZE_LIMIT));
      return;
    }
    const options = { isInline: false };
    item.addFileAttachmentFromBase64Async(content, filename, options, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_ADD_FILES));
      }
    });
  });
};

// Remove attachment
export const removeAttachment = (attachmentId: string) => {
  return new Promise((resolve, reject) => {
    item.removeAttachmentAsync(attachmentId, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_RM_FILES));
      }
    });
  });
};
