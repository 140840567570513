/* global Office, console, document */

import { AboutUser, OfficeItem } from "../../../types";

let item: OfficeItem;

Office.onReady(() => {
  item = Office.context.mailbox.item!;
});

// check outlook type
export const checkOutlookType = (): boolean => {
  return item.itemType === Office.MailboxEnums.ItemType.Message;
};

// Save new item
export const rememberItem = (key: string, value: string | boolean) => {
  try {
    Office.context.roamingSettings.set(key, value);
    Office.context.roamingSettings.saveAsync();
  } catch {
    console.log("Error in remembering item");
    return;
  }
};

// get Required Item
export const getItem = (key: string): string | null => {
  try {
    return Office.context.roamingSettings.get(key);
  } catch {
    console.log("Error in getting item");
    return null;
  }
};

// Remove item
export const forgetItem = (key: string) => {
  try {
    Office.context.roamingSettings.remove(key);
    Office.context.roamingSettings.saveAsync();
  } catch {
    console.log("Error in forgetting item");
    return;
  }
};

// Get current loggedin user
export const currentUser = (): AboutUser | null => {
  try {
    const userProfile = Office.context.mailbox.userProfile;
    if (userProfile) {
      return userProfile;
    }
    return null;
  } catch {
    console.log("Error in getting current use");
    return null;
  }
};

export const clearOfficeStorage = (): void => {
  try {
    Office.context.roamingSettings.remove("senderIomeID");
    Office.context.roamingSettings.remove("receiverIomeID");
    Office.context.roamingSettings.remove("orgID");
    Office.context.roamingSettings.saveAsync();
  } catch (e) {
    console.log(e);
  }
};

export const setCookie = (cookie: string) => {
  document.cookie = cookie;
};

export const getCookie = () => {
  return document.cookie;
};

export const clearCookie = () => {
  document.cookie = `Salt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
