import crypto from "crypto-js";

// Encrypt function
export const AESEncrypt = (plainText: string, key: string): string => {
  const encrypted = crypto.AES.encrypt(plainText, key);
  return encrypted.toString();
};

// Decrypt function
export const AESDecrypt = (encryptedText: string, key: string): string => {
  const decrypted = crypto.AES.decrypt(encryptedText, key);
  return decrypted.toString(crypto.enc.Utf8);
};
