import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { organizationsProps, singleOrg } from "../../../../types";

/* global console */

export const OrganizationsList = ({
  OrgList,
  handleOrgChange,
  org,
  encrypted,
  orgInSubject,
  setSelectedOrg,
}: organizationsProps) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleOrgClick = (org: singleOrg) => {
    if (setSelectedOrg) {
      setSelectedOrg(org);
    }
  };

  console.log(OrgList);

  const defaultOrg = OrgList.find((value: singleOrg) => value.orgName === orgInSubject);

  return (
    <>
      <FormControl disabled={encrypted}>
        <InputLabel id="xoog-org-select">Org</InputLabel>
        <Select
          labelId="xoog-org-select"
          id="org-select"
          value={defaultOrg ? "0x" + defaultOrg.ownerIomeID : org}
          label="Org"
          onChange={(e) => {
            if (handleOrgChange) {
              handleOrgChange(e);
            }
          }}
          MenuProps={MenuProps}
          style={{
            borderRadius: "15px",
            height: "50px",
          }}
        >
          {OrgList.map((org: singleOrg, index: number) => (
            <MenuItem
              value={"0x" + org.ownerIomeID}
              key={index}
              onClick={() => {
                handleOrgClick(org);
              }}
            >
              {org.orgName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          <Typography variant="caption">Required</Typography>
        </FormHelperText>
      </FormControl>
    </>
  );
};
