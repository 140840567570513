// EncryptedDataDisplay.tsx

import { Typography } from "@mui/material";
import React from "react";
import { encrDataDisplayProps } from "../../../types";

/* global navigator, console */

export const EncryptedDataDisplay = ({ encData, loading }: encrDataDisplayProps) => {
  const copyData = async () => {
    try {
      await navigator.clipboard.writeText(encData.join(" "));
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
    <div className="w-full mt-5 flex flex-col gap-y-1 px-4">
      <div className="flex justify-between items-center">
        <Typography variant="caption" className="text-[#5B34F4]">
          Encrypted Data
        </Typography>
        <img
          src="assets/copy.svg"
          alt="copy"
          className={`pe-2 hover:cursor-pointer ${loading && `hidden`}`}
          onClick={copyData}
        />
      </div>

      <div className="w-full border-2 border-[#5B34F4] bg-white p-2 rounded-xl max-h-40 overflow-y-auto">
        {encData.length !== 0 ? (
          encData.map((item, index) => (
            <React.Fragment key={index}>
              <p>{item}</p>
              {index < encData.length - 1 && <hr className="my-2 border-t border-gray-300" />}
            </React.Fragment>
          ))
        ) : (
          <div className="w-full flex justify-center items-center">
            <Typography fontSize={12}>Nothing XooGed yet! XooG now!</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
