import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config/appConfig";
import { DecryptMessage, EmailResponse, FileContent, dialogBoxData, officeContent, singleOrg } from "../../../types";
import {
  DECRYPTION_PROGRESS,
  DECRYPT_PAGE,
  DECRYPT_WINDOW_READY,
  ERR_FETCH_EMAIL,
  ERR_GET_KEYS,
  ERR_INVALID_MESSAGE,
  ERR_SELECT_ORG,
  MESSAGE_DECRYPTED,
  XOOG_SUBJECT_RE,
} from "../../Utils/constants/";
import { Decrypt } from "../../Utils/cryptography/mes";
import { useUser } from "../../context/userContext";
import { getAttachmentDataById } from "../../helpers/office/compose";
import { getAttachments, getBodyHtml, getFrom, getSubject, getTo, openDialogBox } from "../../helpers/office/read";
import { checkOutlookType, currentUser } from "../../helpers/office/storage";
import { StringifyEmailContent } from "../../helpers/user/body";
import { b64ToStr, decryptText } from "../../helpers/user/crypto";
import { getUserOrgs } from "../../helpers/user/userdata";
import { Button } from "../../templates/";
import { DisplayErrors, NetworkDown, NoOrg, OrganizationsList } from "../components/";

/* global console */

const DecyptMessage = () => {
  const { user, setUser } = useUser();
  const [orgList, setOrgList] = useState<singleOrg[]>([]);
  const [Message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<singleOrg | null>(null);
  const [value, setValue] = useState<number>(0);
  const [label, setLabel] = useState<string>("");
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [disableDrop, setDisableDrop] = useState(false);
  const [orgLoader, setOrgLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const setProgress = (val: number, lab: string) => {
    setValue(val);
    setLabel(lab);
  };

  const decryptFiles = async (privateKey: string, publicKey: string) => {
    const decryptedFiles = [];
    const attachments = getAttachments();

    if (attachments.length == 0) {
      return null;
    }

    for (const file of attachments) {
      if (file.name.includes(".xoog")) {
        const attachmentData = await getAttachmentDataById(file.id);
        const encContent: string = b64ToStr(attachmentData.content);
        const encData = encContent.split("|")[0];
        console.log(encData);
        const decryptData = await Decrypt(encData, privateKey, publicKey, false);
        decryptedFiles.push(JSON.parse(decryptData));
      }
    }

    return decryptedFiles.length > 0 ? decryptedFiles : null;
  };

  const handleDecrypt = async () => {
    try {
      setLoading(true);
      setMessage("");
      setErrorMessage("");
      if (checkOutlookType()) {
        setProgress(0, DECRYPTION_PROGRESS[0]);
        const subject: officeContent["Subject"] = getSubject();
        if (!XOOG_SUBJECT_RE.test(subject)) {
          setErrorMessage(ERR_INVALID_MESSAGE);
          setLoading(false);
          return;
        }
        if (!selectedOrg) {
          setErrorMessage(ERR_SELECT_ORG);
          setLoading(false);
          return;
        }
        const newSubject = subject.replace(XOOG_SUBJECT_RE, "");
        setProgress(10, DECRYPTION_PROGRESS[1]);
        const toEmailResponse: EmailResponse[] = getTo();
        if (!toEmailResponse) {
          setErrorMessage(ERR_FETCH_EMAIL);
          setLoading(false);
          return;
        }
        const toEmail: officeContent["EmailAddress"] = toEmailResponse[0].emailAddress;
        const receiverOrgData = selectedOrg.users?.[toEmail];
        setProgress(20, DECRYPTION_PROGRESS[2]);
        const fromEmailResponse: EmailResponse = getFrom();
        if (!fromEmailResponse) {
          setErrorMessage(ERR_FETCH_EMAIL);
          setLoading(false);
          return;
        }
        const fromEmail: officeContent["EmailAddress"] = fromEmailResponse.emailAddress;
        const senderOrgData = selectedOrg.users?.[fromEmail];
        if (!senderOrgData) {
          return;
        }
        setProgress(50, DECRYPTION_PROGRESS[5]);
        const bodyHTML = await getBodyHtml();
        const bodyConent: string = StringifyEmailContent(bodyHTML);
        setProgress(60, DECRYPTION_PROGRESS[6]);
        let reciverPk: string = "";
        let senderPub: string = "";
        if (fromEmail !== currentUser()?.emailAddress) {
          reciverPk = user.key;
          senderPub = senderOrgData.pubKeys.ed25519;
        } else {
          senderPub = receiverOrgData.pubKeys.ed25519;
          reciverPk = user.key;
        }
        if (!reciverPk) {
          setErrorMessage(ERR_GET_KEYS);
          setLoading(false);
          return;
        }

        setProgress(70, DECRYPTION_PROGRESS[7]);
        const decryptData: DecryptMessage = await decryptText(bodyConent, reciverPk, senderPub);
        const decryptedFiles: FileContent[] | null = await decryptFiles(reciverPk, senderPub);
        const Data: dialogBoxData = {
          message: decryptData,
          senderEmail: fromEmail,
          receiverEmail: toEmail,
          subject: newSubject,
          files: decryptedFiles,
          webappURL: config.XOOG_WEBAPP + "/de-xooger/",
        };
        setProgress(100, DECRYPTION_PROGRESS[8]);
        openDialogBox(Data, DECRYPT_PAGE, DECRYPT_WINDOW_READY);
        setMessage(MESSAGE_DECRYPTED);
        setLoading(false);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const getOrgs = async (): Promise<void> => {
    try {
      console.log("decrypt pane");
      setOrgList([]);
      setOrgLoader(true);
      const Orgs: singleOrg[] = await getUserOrgs(user.participantID);
      setOrgList(Orgs);
      const subject: officeContent["Subject"] = getSubject();
      const match = XOOG_SUBJECT_RE.exec(subject);
      if (match) {
        const orgname = match[0].replace("(Secured by ", "").replace(" @Xoog)", "");
        setMessage(JSON.stringify(match));
        setMessage(orgname);
        if (orgname) {
          const matchingOrg = Orgs.find((org) => org.orgName.trim() === orgname.trim());
          if (matchingOrg) {
            setSelectedOrg(matchingOrg);
            setDisableDrop(true);
          }
        }
      }
      setOrgLoader(false);
    } catch (err) {
      console.log(err);
      setNetworkError(true);
      setOrgLoader(false);
    }
  };

  useEffect(() => {
    if (!user) {
      setUser(null);
    }
    getOrgs();
  }, []);

  return (
    <div className="w-11/12 flex flex-col items-center justify-center pt-8">
      <div className="w-full flex flex-col my-10">
        {networkError && <NetworkDown />}
        {orgLoader ? (
          <div className="w-full flex items-center justify-center">
            <CircularProgress disableShrink />
          </div>
        ) : orgList.length === 0 && !networkError ? (
          <NoOrg />
        ) : !networkError ? (
          <div className="flex flex-col gap-y-4">
            <Typography fontWeight={500}>Decrypting your email with</Typography>
            <OrganizationsList
              OrgList={orgList}
              org={selectedOrg?.ownerIomeID!}
              encrypted={loading || disableDrop}
              setSelectedOrg={setSelectedOrg}
              orgInSubject={selectedOrg?.orgName}
            />
            <div className="flex items-center justify-center">
              <Button disabled={loading} onClick={handleDecrypt}>
                <span className="text-white">{!loading ? "de-Xoog" : "...de-Xooging"}</span>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <DisplayErrors errorMessage={errorMessage} Message={Message} loading={loading} value={value} label={label} />
    </div>
  );
};

export default DecyptMessage;
