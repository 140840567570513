import { ERR_DECODE, XOOG_PATTERN } from "../../Utils/constants";
import { AESDecrypt, AESEncrypt } from "../../Utils/cryptography/aes";
import { Decrypt, Encrypt } from "../../Utils/cryptography/mes";

/* global console, atob, btoa */

// Encryption
export const encryptText = async (plainText: string, recipientPubKey: string, senderPrivateKey: string) => {
  return await Encrypt(plainText, recipientPubKey, senderPrivateKey);
};

// Decryption
export const decryptText = async (encData: string, reciverPk: string, senderPub: string) => {
  const matches = [...encData.matchAll(XOOG_PATTERN)];
  const decryptedPromises = matches.map(async (match) => {
    const base64String = match[1];
    try {
      const decodedString = await Decrypt(base64String, reciverPk, senderPub);
      return decodedString;
    } catch (err) {
      console.log(err);
      return ERR_DECODE;
    }
  });
  const decryptedResults = await Promise.all(decryptedPromises);
  const decryptedData = encData.replace(XOOG_PATTERN, () => decryptedResults.shift() || "");
  return decryptedData;
};

// Session Encryption
export const encryptSession = (content: string, key: string) => {
  const cipherText = AESEncrypt(content, key);
  return cipherText;
};

// Session Decryption
export const decryptSession = (content: string, key: string) => {
  const plainText = AESDecrypt(content, key);
  return plainText;
};

export const strToB64 = (str: string) => {
  const b64Enc = btoa(str);
  return b64Enc;
};

export const b64ToStr = (str: string) => {
  const b64Dec = atob(str);
  return b64Dec;
};

/* ********************************************************************************** */
