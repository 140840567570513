import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EncryptedMessage, singleOrg } from "../../../types";
import {
  CHAR_LIMIT,
  EMAIL_RE,
  ENCRYPTION_PROGRESS,
  ERR_CHAR_LIMIT,
  ERR_EMAIL,
  ERR_ENCRYPTED_CONTENT,
  ERR_MULTI_RECIPIENTS,
  ERR_NOT_SAME_ORG,
  ERR_NO_RECIPIENT,
  ERR_RECIPIENT_CHANGE,
  ERR_SELECT_ORG,
  ERR_TRY_AGN,
  MESSAGE_ENCRYPTED,
  XOOG_END,
  XOOG_START,
} from "../../Utils/constants/";
import { useUser } from "../../context/userContext";
import {
  getReceiver,
  getSelectedHTML,
  getSelectedText,
  getSubject,
  setSelectedContent,
} from "../../helpers/office/compose";
import { checkOutlookType, rememberItem } from "../../helpers/office/storage";
import { StringifyEmailContent, addSubject, checkMatched, formatCopiedData } from "../../helpers/user/body";
import { encryptText } from "../../helpers/user/crypto";
import { getUserOrgs } from "../../helpers/user/userdata";
import { Button } from "../../templates/";
import { DisplayErrors, EncryptedDataDisplay, NetworkDown, NoOrg, OrganizationsList } from "../components/";
import { EncryptFile } from "../components/encryptFile";

/* global console */

const EncryptMessage = () => {
  const { user, setUser } = useUser();
  const [Message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<singleOrg | null>(null);
  const [orgList, setOrgList] = useState<singleOrg[]>([]);
  const [value, setValue] = useState<number>(0);
  const [label, setLabel] = useState<string>("");
  const [currentRecipient, setCurrentRecipient] = useState<string | null>(null);
  const [encrypted, setEncrypted] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [encData, setEncData] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [orgLoader, setOrgLoader] = useState<boolean>(true);

  const setProgress = (val: number, lab: string) => {
    setValue(val);
    setLabel(lab);
  };

  const HandleEncrypt = async () => {
    try {
      setLoading(true);
      setMessage("");
      setErrorMessage("");
      if (checkOutlookType()) {
        if (!selectedOrg) {
          setErrorMessage(ERR_SELECT_ORG);
          setLoading(false);
          return;
        }
        const RecipientsData = await getReceiver();
        if (!RecipientsData || RecipientsData.length === 0) {
          setErrorMessage(ERR_NO_RECIPIENT);
          setLoading(false);
          return;
        }
        if (RecipientsData.length > 1) {
          setErrorMessage(ERR_MULTI_RECIPIENTS);
          setLoading(false);
          return;
        }
        let RecipientEmail = "";
        if (EMAIL_RE.test(RecipientsData[0].emailAddress)) {
          RecipientEmail = RecipientsData[0].emailAddress;
        } else if (EMAIL_RE.test(RecipientsData[0].displayName)) {
          RecipientEmail = RecipientsData[0].displayName;
        } else {
          setErrorMessage(ERR_EMAIL);
          return;
        }

        const receiverOrgData = selectedOrg.users?.[RecipientEmail];

        if (!receiverOrgData) {
          setErrorMessage(ERR_NOT_SAME_ORG);
          setLoading(false);
          return;
        }

        if (receiverOrgData.status !== "activated") {
          setErrorMessage("Recipient is not a active user in the Organisation");
          setLoading(false);
          return;
        }

        setProgress(50, ENCRYPTION_PROGRESS[5]);
        const subject = await getSubject();
        setProgress(60, ENCRYPTION_PROGRESS[6]);
        const recipientPubKey: string = receiverOrgData?.pubKeys?.ed25519;
        const senderPrivateKey: string = user.key;

        setProgress(70, ENCRYPTION_PROGRESS[7]);
        const selectedTextResponse = await getSelectedText();
        const selectedText = selectedTextResponse.data;

        if (!selectedText.trim()) {
          setErrorMessage(ERR_TRY_AGN);
          setLoading(false);
          return;
        }

        if (selectedText) {
          if (selectedText.length > CHAR_LIMIT) {
            setLoading(false);
            setErrorMessage(ERR_CHAR_LIMIT);
            return;
          }
          const matchString = await checkMatched(selectedText);
          if (matchString) {
            setErrorMessage(ERR_ENCRYPTED_CONTENT);
            setLoading(false);
            await addSubject(subject, selectedOrg.orgName);
            return;
          }
          const selectedContentHTML = await getSelectedHTML();
          const selectedContent = StringifyEmailContent(selectedContentHTML.data);
          setProgress(80, ENCRYPTION_PROGRESS[8]);
          const encSelected: EncryptedMessage = await encryptText(selectedContent, recipientPubKey, senderPrivateKey);
          setEncData([...encData, formatCopiedData(selectedContent)]);
          const newSelectedContent = ` ${XOOG_START}${encSelected}${XOOG_END} `;
          await setSelectedContent(newSelectedContent);
          await addSubject(subject, selectedOrg.orgName);
        } else {
          setErrorMessage(ERR_TRY_AGN);
          setLoading(false);
          return;
        }
        if (!currentRecipient) {
          setCurrentRecipient(RecipientEmail);
        } else if (currentRecipient !== RecipientEmail) {
          setErrorMessage(ERR_RECIPIENT_CHANGE);
          setLoading(false);
          return;
        }
        rememberItem("receiverParticipantID", receiverOrgData.participantID);
        rememberItem("senderParticipantID", user.participantID.slice(4));
        rememberItem("orgID", selectedOrg.orgID);
        setEncrypted(true);
        setMessage(MESSAGE_ENCRYPTED);
        setProgress(100, ENCRYPTION_PROGRESS[10]);
        setLoading(false);
        console.log("receiverParticipantID", receiverOrgData.participantID);
        console.log("senderParticipantID", user.participantID.slice(4));
        console.log("orgID", selectedOrg.orgID);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const getOrgs = async () => {
    try {
      setOrgList([]);
      setOrgLoader(true);
      const Orgs: singleOrg[] = await getUserOrgs(user.participantID);
      setOrgList(Orgs);
      setOrgLoader(false);
      return;
    } catch (err: any) {
      setOrgLoader(false);
      setNetworkError(true);
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!user) {
      setUser(null);
    }
    getOrgs();
  }, []);

  return (
    <div className="w-11/12 flex flex-col items-center justify-center">
      <div className="w-full flex flex-col">
        {networkError && <NetworkDown />}
        {orgLoader ? (
          <div className="w-full flex items-center justify-center">
            <CircularProgress disableShrink />
          </div>
        ) : orgList.length === 0 && !networkError ? (
          <NoOrg />
        ) : (
          !networkError && (
            <div className="flex flex-col gap-y-4">
              <Typography fontWeight={500}>Encryption your email with</Typography>
              {orgList && (
                <OrganizationsList
                  OrgList={orgList}
                  org={selectedOrg?.ownerIomeID!}
                  encrypted={encrypted}
                  setSelectedOrg={setSelectedOrg}
                />
              )}
              <div className="flex items-center justify-center">
                <Button disabled={loading} onClick={HandleEncrypt}>
                  <span className="text-white"> {!loading ? "XooG It" : "XooGing..."}</span>
                </Button>
              </div>
              <EncryptedDataDisplay encData={encData} loading={loading} />
              <EncryptFile
                user={user}
                selectedOrg={selectedOrg}
                setErrorMessage={setErrorMessage}
                setEncrypted={setEncrypted}
              />
            </div>
          )
        )}
      </div>
      <DisplayErrors errorMessage={errorMessage} Message={Message} loading={loading} value={value} label={label} />
    </div>
  );
};

export default EncryptMessage;
