import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import { ProgressBarProps } from "../../types";

export const ProgressBar = ({ value, className, label }: ProgressBarProps) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <CircularProgress variant="determinate" value={value} className={className} />
      <Typography variant="body2" className="pt-4">
        {label}
      </Typography>
    </div>
  );
};
