import MES, { Utils, XsalsaNacl } from "@zenz-solutions/js-mes";
import { EncryptionOptions, Pk, Pub } from "@zenz-solutions/js-mes/src/types";

/* global atob, btoa, TextDecoder */

const _xsalsa_Nacl: XsalsaNacl = new XsalsaNacl();
const mes: MES = new MES(_xsalsa_Nacl);

export const Encrypt = async (
  message: string,
  publicKey: string,
  privateKey: string,
  toB64: boolean = true
): Promise<string> => {
  const messageBytes = Utils.fromString(message);
  const rPub: Pub = {
    type: "ed25519",
    publicKey: Utils.fromHexString(publicKey),
  };
  const sPri: Pk = {
    type: "secp256k1",
    privateKey: Utils.fromHexString(privateKey.slice(2)),
  };
  const encryptionOps: EncryptionOptions = {
    sPriv: sPri,
  };
  const fDigest: Uint8Array = await mes.encrypt(messageBytes, rPub, true, encryptionOps);
  if (!toB64) {
    return fDigest.toString();
  }
  return btoa(fDigest.toString());
};

export const Decrypt = async (
  encMessage: string,
  privateKey: string,
  publicKey: string,
  isB64: boolean = true
): Promise<string> => {
  let encryptedBytes: Uint8Array;
  if (!isB64) {
    encryptedBytes = new Uint8Array(encMessage.split(",").map(Number));
  } else {
    encryptedBytes = new Uint8Array(atob(encMessage).split(",").map(Number));
  }

  const sPub: Pub = {
    type: "ed25519",
    publicKey: Utils.fromHexString(publicKey),
  };

  const rPri: Pk = {
    type: "secp256k1",
    privateKey: Utils.fromHexString(privateKey.slice(2)),
  };

  const decryptionOps: EncryptionOptions = {
    sPub: sPub,
  };

  const decryptedBytes: Uint8Array = await mes.decrypt(encryptedBytes, rPri, true, decryptionOps);
  const decryptedMessage: string = new TextDecoder().decode(decryptedBytes);

  return decryptedMessage;
};
