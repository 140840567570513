// ERROR MESSAGES
export const ERR_INVALID_MESSAGE: string = "Invalid XOOG message";
export const ERR_BODY_EMPTY: string = "Body is empty";
export const ERR_SELECT_ORG: string = "Please select your organization";
export const ERR_NOT_SAME_ORG: string = "Users are not in the same organization";
export const ERR_NOT_ACTIVE: string = "User not an activte part of organization";
export const ERR_EMPTY_MESSAGE: string = "No message found in the body";
export const ERR_NO_RECIPIENT: string = "Please add a recipient";
export const ERR_MULTI_RECIPIENTS: string = "This version of XOOG supports only one recipient per email";
export const ERR_UNKNOWN: string = "Oops! Something went wrong. Please select some text and try again";
export const ERR_ENCRYPTED_CONTENT: string = "An encrypted content already exists";
export const ERR_RECIPIENT_CHANGE = "Cannot encrypt, recipient has been changed. Reload your addin.";
export const ERR_DECODE = "Error occurred while decoding this message";
export const ERR_NETWORK = "There seems to be a problem. Please try again";
export const ERR_TRY_AGN = "Please Select some content and try again";
export const ERR_CHAR_LIMIT: string = "Character limit exceeded, please select less than 10,000 characters";
export const ERR_FILE_SIZE_LIMIT: string = "File size limit exceeded";

// NETWORK ERRORS
export const ERR_FETCH_EMAIL: string = "Error in fetching email";
export const ERR_GET_BODY: string = "Error in retrieving body. Please select again and try.";
export const ERR_SET_BODY: string = "Error in setting body";
export const ERR_GET_SUBJECT: string = "Error in fetching subject";
export const ERR_SET_SUBJECT: string = "Error in setting subject";
export const ERR_GET_KEYS: string = "Error in retrieving keys";
export const ERR_GET_FILES: string = "Error in getting the files";
export const ERR_ADD_FILES: string = "Error in adding files";
export const ERR_RM_FILES: string = "Error in removing files";
export const ERR_LOGIN: string = "Oops! There was a glitch. Please try again later";
export const ERR_EMAIL: string = "There seems to be some problem with the mail";
export const ERR_DIALOG_BOX: string =
  "Unable to open new dialog box. Please close the existing dialog box and try again";
