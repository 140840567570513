// config.ts

/* global process */

interface AppConfig {
  BASE_URL: string;
  BACKEND_URL: string;
  XOOG_WEBAPP: string;
  DEFAULT_IGC_PATH: string;
  IOME_BASE_URL: string;
  IOME_APP_URL: string;
  MANIFEST_VERSION: string;
}

const environments: { [key: string]: AppConfig } = {
  PROD: {
    BASE_URL: process.env.PROD_URL as string,
    BACKEND_URL: process.env.PROD_BACKEND_URL as string,
    XOOG_WEBAPP: process.env.PROD_XOOG_WEBAPP as string,
    DEFAULT_IGC_PATH: process.env.DEFAULT_IGC_PATH as string,
    IOME_BASE_URL: process.env.PROD_IOME_BASE_URL as string,
    IOME_APP_URL: process.env.PROD_IOME_APP_URL as string,
    MANIFEST_VERSION: process.env.MANIFEST_VERSION as string,
  },
  QA: {
    BASE_URL: process.env.QA_URL as string,
    BACKEND_URL: process.env.QA_BACKEND_URL as string,
    XOOG_WEBAPP: process.env.QA_XOOG_WEBAPP as string,
    DEFAULT_IGC_PATH: process.env.DEFAULT_IGC_PATH as string,
    IOME_BASE_URL: process.env.QA_IOME_BASE_URL as string,
    IOME_APP_URL: process.env.QA_IOME_APP_URL as string,
    MANIFEST_VERSION: process.env.MANIFEST_VERSION as string,
  },
  LOCALHOST: {
    BASE_URL: process.env.LOCALHOST_URL as string,
    BACKEND_URL: process.env.LOCALHOST_BACKEND_URL as string,
    XOOG_WEBAPP: process.env.QA_XOOG_WEBAPP as string,
    DEFAULT_IGC_PATH: process.env.DEFAULT_IGC_PATH as string,
    IOME_BASE_URL: process.env.QA_IOME_BASE_URL as string,
    IOME_APP_URL: process.env.QA_IOME_APP_URL as string,
    MANIFEST_VERSION: process.env.MANIFEST_VERSION as string,
  },
};
const currentEnvironment: string = process.env.APP_ENV || "PROD";
const config: AppConfig = environments[currentEnvironment];

export default config;
