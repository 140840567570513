// api.ts
import axios, { AxiosInstance, AxiosResponse } from "axios";
import config from "../../../config/appConfig";

/* global console */

class CustomAxios {
  private axiosInstance: AxiosInstance;

  constructor(baseURL?: string) {
    this.axiosInstance = axios.create({
      baseURL,
    });
  }

  public async get<T>(url: string, headers = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get(url, { headers });
      return response.data;
    } catch (error) {
      console.error("Axios GET request failed", error);
      throw error;
    }
  }

  public async post<T>(url: string, data: any, headers = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, { headers });
      return response.data;
    } catch (error) {
      console.error("Axios POST request failed", error);
      throw error;
    }
  }
}

const axiosBackendInstance = new CustomAxios(config.BACKEND_URL);

export const fetchUserOrgs = async (moiID: string) => {
  const response = (await axiosBackendInstance.get(`/xoog/v0/users/${moiID}/orgs`, {
    "ngrok-skip-browser-warning": "abc",
  })) as AxiosResponse;
  return response.data;
};
