import { PickUpLines } from "../../../types";

export const MESSAGE_ENCRYPTED: string = "Message encrypted successfully";
export const MESSAGE_DECRYPTED: string = "Message decrypted successfully";
export const DECRYPT_MESSAGE: string = "Message decrypted successfully";
export const XOOG_SUBJECT: string = "(Secured by XOOG)";
export const XOOG_START: string = "X009:";
export const XOOG_END: string = ":X009";
export const ENCRYPTION_PROGRESS: string[] = [
  "Getting subject",
  "Getting organizations",
  "Validating current user",
  "Getting receiver",
  "Getting receiver organizations",
  "Getting keys",
  "Getting plaintext",
  "Encrypting",
  "Setting subject",
  "Done",
];
export const DECRYPTION_PROGRESS: string[] = [
  "Validating subject",
  "Validating receiver",
  "Validating sender",
  "Validating organizations",
  "Validating user",
  "Validating body",
  "Getting keys",
  "Decrypting data",
  "Done",
];
export const XOOG_FILES_ENC = "Note: This version of xoog does not encrypt your files";

export const pickUpLines: PickUpLines[] = [
  {
    icon: "assets/privacy.svg",
    heading: "End-to-End Privacy Assurance",
    sub: "Embrace true end-to-end privacy for your emails",
  },
  {
    icon: "assets/seamless.svg",
    heading: "Seamless Integration",
    sub: "Works with minimal setup directly into your Outlook environment.",
  },
  {
    icon: "assets/secure.svg",
    heading: "Tamper-Proof Authentication",
    sub: "Bid farewell to email spoofing and unauthorized access. Each email is securely signed.",
  },
];
