/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config/appConfig";
import { User } from "../../../types";
import { useUser } from "../../context/userContext";
import { decryptUserPrivateKey, encryptUserPrivateKey } from "../../helpers/office/session";
import { clearCookie, currentUser, getItem, rememberItem } from "../../helpers/office/storage";
import { decryptSession, encryptSession } from "../../helpers/user/crypto";
import DecyptMessage from "./DecryptMessage";
import EncryptMessage from "./EncryptMessage";
// @ts-ignore
import { Connect, IOMe } from "@iome/react-widget";
import { fetchPrivateKey } from "../../helpers/user/userdata";
import { NetworkDown } from "../components";
import Spalsh from "../components/splash";

/* global process, Office, document, console, setTimeout, HTMLElement */

const Home = () => {
  const { setUser, user } = useUser();
  const [Iome, setIome] = useState<any>(null);
  const [iomeError, setIomeError] = useState<boolean>(false);
  const [showHome, setShowHome] = useState(false);
  const [itemChanged, setItemChanged] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [renderKey, setRenderKey] = useState(0);

  const onSuccess = async (data: any) => {
    try {
      console.log("onSuccess called");
      const userPrivateKey = fetchPrivateKey(data.user.SRP());
      const agent = Office.context.platform as unknown as string;
      const encPK = await encryptUserPrivateKey(userPrivateKey, agent, config.MANIFEST_VERSION);

      if (data) {
        const userData: User = {
          userName: data.userName,
          userID: data.user.userID,
          participantID: data.user.ParticipantID,
          key: encPK,
        };
        rememberItem("userName", data.userName);
        const currentUserEmail = currentUser()!;
        const encSessData = encryptSession(JSON.stringify(userData), currentUserEmail?.emailAddress);
        rememberItem("sessionData", encSessData);
        userData.key = userPrivateKey;
        setUser(userData);
      }
    } catch (err) {
      console.error("Error during onSuccess:", err);
      setUser(null);
      setIome(null);
    }
  };

  const initializeIOMe = async () => {
    try {
      const iome = new IOMe(process.env.DEVELOPER_ID, process.env.APP_ID, {
        baseURL: config.IOME_BASE_URL,
        iomeAppUrl: config.IOME_APP_URL,
      });
      await iome.InitDev();
      await iome.InitApp();
      setIome(iome);
    } catch (err) {
      console.error("Failed to initialize IOMe:", err);
      setIomeError(true);
    }
  };

  const checkRequirements = () => {
    return Office.context.requirements.isSetSupported("Mailbox", "1.12");
  };

  Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
    setItemChanged(true);
    setFirstLoad(false);
    if (user) {
      setRenderKey((prevKey) => prevKey + 1);
    }
  });

  const initialize = async () => {
    try {
      if (!user) {
        initializeIOMe();
        const currentUserEmail = currentUser()!;
        const EncSessData = getItem("sessionData")!;

        if (EncSessData) {
          try {
            const decSessData = decryptSession(EncSessData, currentUserEmail.emailAddress);
            const parsedSession = JSON.parse(decSessData);
            const encKey = parsedSession.key;
            const agent = Office.context.platform as unknown as string;
            const decPrivateKey = await decryptUserPrivateKey(encKey, agent, config.MANIFEST_VERSION);
            console.log(decPrivateKey);
            if (!decPrivateKey) {
              setUser(null);
              return;
            }
            parsedSession.key = decPrivateKey;
            setUser(parsedSession);
          } catch (err) {
            console.error("Decryption faileda:", err);
            setUser(null);
            setIome(null);
            clearCookie();
          }
        } else {
          if (!Iome) {
            initializeIOMe();
          }
        }
      }
    } catch (err) {
      console.error("Initialization error:", err);
      setIomeError(true);
    }
  };

  useEffect(() => {
    initialize();
    if (!itemChanged && firstLoad) {
      setTimeout(() => {
        setShowHome(true);
      }, 3000);
    } else {
      setShowHome(true);
    }
  }, [itemChanged, user]);

  useEffect(() => {
    if (Iome) {
      const btn = document.getElementsByClassName("login-btn")[0] as HTMLElement;
      if (btn) {
        btn.style.backgroundColor = "#000b80";
      }
    }
  }, [Iome, showHome, user]);

  return (
    <div key={renderKey} className="w-full">
      {!checkRequirements() ? (
        <div className="w-full flex items-center justify-center h-screen p-5">
          <p>
            Unfortunately, the Addin is not compatible with your current version of Outlook. Please upgrade to the
            latest version with modern UI for optimal functionality.
          </p>
        </div>
      ) : (
        <>
          <Spalsh showSplash={itemChanged} />
          {showHome &&
            (!user ? (
              <div className="w-full flex justify-center mt-10">
                {Iome ? <Connect iome={Iome} onSuccess={onSuccess} /> : <CircularProgress disableShrink />}
              </div>
            ) : !iomeError ? (
              <div className="mt-14 w-full flex items-center justify-center">
                {Office.context.mailbox.item!.displayReplyForm === undefined
                  ? user && <EncryptMessage />
                  : user && <DecyptMessage />}
              </div>
            ) : (
              <NetworkDown />
            ))}
        </>
      )}
    </div>
  );
};

export default Home;
