import React from "react";

const Spalsh = ({ showSplash }: any) => {
  return (
    <div className="w-full flex flex-col items-center mt-10">
      <div className="relative">
        <img
          src="/assets/xoog-dark.svg"
          alt="xoog logo"
          className={!showSplash ? "animate-fadeInThenMoveUp" : ""}
          width={130}
        />
      </div>
    </div>
  );
};

export default Spalsh;
