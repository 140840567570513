import config from "../../../config/appConfig";
import { OfficeItem, dialogBoxData } from "../../../types";
import { ERR_DIALOG_BOX, ERR_GET_BODY } from "../../Utils/constants";
/* global Office */

let item: OfficeItem;

Office.onReady(() => {
  item = Office.context.mailbox.item!;
});

// Get the contents of body while composing
export const getBodyHtml = (): Promise<string> => {
  return new Promise((resolve: (result: string) => void, reject: (error: Error) => void) => {
    item!.body.getAsync(Office.CoercionType.Html, (result: Office.AsyncResult<string>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_GET_BODY));
      }
    });
  });
};

// Get the recipient Email while reading
export const getTo = () => {
  return item.to;
};

// Get the sender Email while reading
export const getFrom = () => {
  return item.from;
};

// Get the subject while reading
export const getSubject = () => {
  return item.subject;
};

// get body
export const getBody = () => {
  return item.body;
};

// get attachments
export const getAttachments = () => {
  return item.attachments;
};

// Get the contents of body while reading
export const getBodyText = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    item.body.getAsync(Office.CoercionType.Text, (result: Office.AsyncResult<string>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(new Error(ERR_GET_BODY));
      }
    });
  });
};

// Function to open Dialog Box
export const openDialogBox = (Data: dialogBoxData, page: string, signature: string) => {
  let dialog: Office.Dialog;
  Office.context.ui.displayDialogAsync(
    `${config.BASE_URL}/${page}`,
    { height: 80, width: 80, displayInIframe: true },
    (asyncResult: Office.AsyncResult<Office.Dialog>) => {
      dialog = asyncResult.value;
      try {
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
          if (arg.message === signature) {
            dialog.messageChild(JSON.stringify({ Data }));
          }
        });
      } catch {
        throw new Error(ERR_DIALOG_BOX);
      }
    }
  );
};
