import type { FC, ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";
import { User } from "../../types";

export const userContext = createContext<any>(undefined);

export const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const value = { user, setUser };
  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

export const useUser = () => {
  const { user, setUser } = useContext(userContext);
  return { user, setUser };
};
