import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { UserContextProvider } from "../context/userContext";
import "./taskpane.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../config/theme";

/* global document, Office, module, HTMLElement */

const rootElement: HTMLElement = document.getElementById("container") as HTMLElement;
const root = createRoot(rootElement);

const OfficeApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </ThemeProvider>
  );
};

Office.onReady(() => {
  root.render(<OfficeApp />);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    root.render(<OfficeApp />);
  });
}
