import { Typography } from "@mui/material";
import React, { useState } from "react";
import config from "../../../config/appConfig";
import { useUser } from "../../context/userContext";
import { clearCookie, forgetItem } from "../../helpers/office/storage";
import { redirectTo } from "../../helpers/user/userdata";

export interface FooterPropType {
  email: string;
  firstTimeUser: boolean;
}

export const Footer = ({ email, firstTimeUser }: FooterPropType) => {
  const { setUser, user } = useUser();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const handleLogout = () => {
    setUser(null);
    setShowOptions(false);
    clearCookie();
    forgetItem("sessionData");
    forgetItem("senderIomeID");
    forgetItem("receiverIomeID");
  };

  const handleDashboardClick = () => {
    setShowOptions(false);
    redirectTo(config.XOOG_WEBAPP);
  };

  return (
    <div className={`flex flex-col gap-y-2 w-full bottom-0 ${!firstTimeUser ? "fixed" : ""}`}>
      <div className="flex flex-row-reverse mx-4">
        {showOptions && (
          <div className="bg-white rounded-sm p-2 flex flex-col gap-y-4">
            <div className="flex gap-x-4 hover:cursor-pointer">
              <img src="assets/profile.svg" alt="logout" className="w-5 h-5" />
              <Typography>{user.userName.length > 10 ? `${user.userName.slice(0, 8)}...` : user.userName}</Typography>
            </div>
            <div className="flex gap-x-4 hover:cursor-pointer">
              <img src="assets/mail.svg" alt="logout" className="w-5 h-5" />
              <Typography fontSize={12}>{email.length > 13 ? `${email.slice(0, 11)}...` : email}</Typography>
            </div>
            <div className="flex gap-x-4 hover:cursor-pointer" onClick={handleLogout}>
              <img src="assets/logout.svg" alt="logout" />
              <Typography>Logout</Typography>
            </div>
            <div className="flex gap-x-4 hover:cursor-pointer" onClick={handleDashboardClick}>
              <img src="assets/nav.svg" alt="dashboard" />
              <Typography>Dashboard</Typography>
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col items-center">
        <div className={`w-11/12 flex items-center mx-4 my-1 ${user ? "justify-between" : "justify-center"}`}>
          <div className="flex hover:cursor-pointer gap-1 items-center justify-center">
            <Typography variant="overline" className="text-indigo-700">
              Powered By MOI
            </Typography>
            <img src="assets/moi.svg" alt="redirect" className="text-indigo-700 w-5 h-5" />
          </div>

          {user && (
            <div
              onClick={() => {
                setShowOptions((prev) => !prev);
              }}
              className="bg-indigo-700 hover:cursor-pointer p-2 rounded-full w-9 h-9 flex items-center justify-center"
            >
              <Typography className="text-white">{user.userName[0].toUpperCase()}</Typography>
            </div>
          )}
        </div>
        <Typography fontSize={8}>V0.4.0</Typography>
      </div>
    </div>
  );
};
