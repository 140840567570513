import { Button, Typography } from "@mui/material";
import React from "react";
import config from "../../../../config/appConfig";
import { redirectTo } from "../../../helpers/user/userdata";

export const NoOrg = () => {
  const openWebApp = () => {
    redirectTo(config.XOOG_WEBAPP);
  };

  return (
    <div className="w-full flex flex-col gap-y-4 items-center justify-center h-[500px]">
      <Typography variant="subtitle1" fontWeight={500}>
        Oops! You are not part of any organisation
      </Typography>
      <Button onClick={openWebApp} variant="contained" style={{ backgroundColor: "#5B34F4", borderRadius: "15px" }}>
        Go to Xoog
      </Button>
    </div>
  );
};
