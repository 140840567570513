import React from "react";
import { Typography } from "@mui/material";

export const NetworkDown = () => (
  <div className="w-full flex flex-col gap-y-4 items-center justify-center h-[500px]">
    <Typography variant="subtitle1" fontWeight={500}>
      There seems to be some network error. Please try again after some time.
    </Typography>
  </div>
);
