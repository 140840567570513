// @ts-ignore
import { singleOrg } from "../../../types";
import { fetchUserOrgs } from "../requests/api";
// @ts-ignore
import { Utils as IomeUtils } from "@iome/react-widget";
import config from "../../../config/appConfig";
import { currentUser } from "../office/storage";

/* global window, console */

// Function to get the orgs accociated with current email
export const getUserOrgs = async (moiID: string): Promise<any> => {
  // get the current logged in user in outlook
  const currentUserData = currentUser();

  if (!currentUserData) {
    return null;
  }

  const modifiedMoiID = moiID.slice(4);

  // variable to store the user related orgs
  const emailAssociatedOrgs: any = [];

  // fetch the orgs of the iome user.
  const userOrgs: singleOrg[] = await fetchUserOrgs(modifiedMoiID);

  console.log(userOrgs);

  const outlookUserEmail = currentUserData.emailAddress;

  console.log(outlookUserEmail);
  console.log(modifiedMoiID);

  userOrgs.forEach((org: singleOrg) => {
    if (
      org.emailUsed === outlookUserEmail &&
      org.users[outlookUserEmail].participantID === modifiedMoiID &&
      org.users[outlookUserEmail].status === "activated"
    ) {
      emailAssociatedOrgs.push(org);
    }
  });

  // return the list of associated orgs
  return emailAssociatedOrgs;
};

// function to fetch private key of current user
export const fetchPrivateKey = (SRP: string): string => {
  const hdw = new IomeUtils.wallet();
  hdw.FromSRP(SRP);
  const deriveAccount = hdw.ReturnDerivedAccount(config.DEFAULT_IGC_PATH);
  const pk: string = deriveAccount.signingKey.keyPair.privateKey as string;
  return pk;
};

// Redirect URLs
export const redirectTo = (url: string) => {
  window.open(url, "_blank")?.location.href;
};
