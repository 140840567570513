import { Button, Typography } from "@mui/material";
import React from "react";
import { GettingStartedProps } from "../../../types";
import { rememberItem } from "../../helpers/office/storage";
import { Gettingstarted } from "../components/";

const GettingStarted = ({ setFirstTimeUser }: GettingStartedProps) => {
  const changeSettings = () => {
    rememberItem("regularUser", true);
    setFirstTimeUser(false);
  };

  return (
    <div className="w-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center justify-center px-4">
        <img src="assets/xoog-dark.svg" alt="xoog logo" className="w-36 h-20" />
        <Typography variant="subtitle1" fontWeight={500} className="text-center pb-8">
          Fortify Your Enterprise Communications on Outlook with Robust Security
        </Typography>
        <Gettingstarted />
        <div className="pt-12">
          <Button
            variant="contained"
            onClick={changeSettings}
            style={{ backgroundColor: "#5B34F4", borderRadius: "15px" }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
