import * as React from "react";
import { useEffect, useState } from "react";
import { clearOfficeStorage, currentUser, getItem } from "../helpers/office/storage";
import { Footer } from "./components";
import GettingStarted from "./panes/GettingStarted";
import Home from "./panes/Home";

const App = () => {
  const [firstTimeUser, setFirstTimeUser] = useState<boolean | null>(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    // rememberItem("regularUser", false);
    // forgetItem("userData");
    clearOfficeStorage();
    const isFirstTimeUser = getItem("regularUser");
    if (!isFirstTimeUser) {
      setFirstTimeUser(true);
    } else {
      setFirstTimeUser(false);
    }
  }, []);

  useEffect(() => {
    const user = currentUser();
    const emailID = user?.emailAddress;
    setEmail(emailID ?? "");
  });

  return (
    <div className="w-full ">
      <div className="flex flex-col items-center h-screen">
        {firstTimeUser ? <GettingStarted setFirstTimeUser={setFirstTimeUser} /> : <Home />}
        <Footer email={email} firstTimeUser={firstTimeUser ?? false} />
      </div>
    </div>
  );
};

export default App;
