import { Typography } from "@mui/material";
import React, { useState } from "react";
import { FileData, encryptFileProps, officeContent } from "types";
import { EMAIL_RE, ERR_EMAIL, ERR_MULTI_RECIPIENTS, ERR_NO_RECIPIENT, ERR_SELECT_ORG } from "../../Utils/constants";
import { getAttachments, getReceiver, getSender, getSubject, removeAttachment } from "../../helpers/office/compose";
import { rememberItem } from "../../helpers/office/storage";
import { addSubject, encryptAttachments } from "../../helpers/user/body";

/* global console */

export const EncryptFile = ({ user, setErrorMessage, setEncrypted, selectedOrg }: encryptFileProps) => {
  const [attachmentsData, setattachmentsData] = useState<null | FileData[]>(null);
  const [loadingIndex, setLoadingIndex] = useState<null | number>(null);

  const getAttachmentsList = async () => {
    setLoadingIndex(null);
    setErrorMessage("");
    const fileNames: FileData[] = [];
    const attachmentsList = await getAttachments();
    attachmentsList.forEach((file) => {
      fileNames.push({
        id: file.id,
        name: file.name,
        isEncrypted: file.name.includes(".xoog"),
      });
    });
    fileNames.length !== 0 ? setattachmentsData(fileNames) : setattachmentsData(null);
  };

  const handleRemoveFile = async (fileID: string) => {
    await removeAttachment(fileID);
    await getAttachmentsList();
  };

  const handleFileEncrypt = async (file: FileData, index: number) => {
    setErrorMessage("");
    setLoadingIndex(index);
    if (!user) {
      setLoadingIndex(null);
      setErrorMessage("Not Authorized");
      return;
    }
    try {
      const fileID = file.id;
      const fileName = file.name;
      if (!selectedOrg) {
        setLoadingIndex(null);
        setErrorMessage(ERR_SELECT_ORG);
        return;
      }
      const senderEmailData = await getSender();
      var senderEmail: officeContent["EmailAddress"];
      if (EMAIL_RE.test(senderEmailData.emailAddress)) {
        senderEmail = senderEmailData.emailAddress;
      } else if (EMAIL_RE.test(senderEmailData.displayName)) {
        senderEmail = senderEmailData.displayName;
      } else {
        setErrorMessage(ERR_EMAIL);
        setLoadingIndex(null);
        return;
      }
      const senderOrgData = selectedOrg.users?.[senderEmail];
      const RecipientsData = await getReceiver();
      if (!RecipientsData || RecipientsData.length === 0) {
        setErrorMessage(ERR_NO_RECIPIENT);
        setLoadingIndex(null);
        return;
      }
      if (RecipientsData.length > 1) {
        setErrorMessage(ERR_MULTI_RECIPIENTS);
        setLoadingIndex(null);
        return;
      }
      let RecipientEmail = "";
      if (EMAIL_RE.test(RecipientsData[0].emailAddress)) {
        RecipientEmail = RecipientsData[0].emailAddress;
      } else if (EMAIL_RE.test(RecipientsData[0].displayName)) {
        RecipientEmail = RecipientsData[0].displayName;
      } else {
        setErrorMessage(ERR_EMAIL);
        setLoadingIndex(null);
        return;
      }
      const receiverOrgData = selectedOrg.users?.[RecipientEmail];
      const recipientPubKey: string = receiverOrgData.pubKeys.ed25519;
      const senderPrivateKey: string = user.key;
      const orgID = selectedOrg.orgID;
      const senderPubKey = senderOrgData.pubKeys.ed25519;
      await encryptAttachments(fileID, fileName, recipientPubKey, senderPrivateKey, orgID, senderPubKey);
      getAttachmentsList();
      const subject = await getSubject();
      await addSubject(subject, selectedOrg.orgName!);
      setEncrypted(true);
      setLoadingIndex(null);
      rememberItem("receiverParticipantID", receiverOrgData.participantID);
      rememberItem("senderParticipantID", user.participantID.slice(4));
      rememberItem("orgID", selectedOrg.orgID);

      console.log("receiverParticipantID", receiverOrgData.participantID);
      console.log("senderParticipantID", user.participantID.slice(4));
      console.log("orgID", selectedOrg.orgID);
    } catch (err: any) {
      setErrorMessage(JSON.stringify(err.message));
      setLoadingIndex(null);
      console.log(err.message);
    }
  };

  return (
    <div className="w-full mt-5 flex flex-col gap-y-1 px-4">
      <div className="flex justify-between items-center">
        <Typography fontSize={12} variant="caption" className="text-[#5B34F4]">
          Encrypt Files
        </Typography>
        <div className="cursor-pointer" onClick={getAttachmentsList}>
          <img src="assets/refresh.svg" alt="refresh" width={12} height={12} />
        </div>
      </div>
      <div className="w-full pt-2">
        {attachmentsData ? (
          <div className="flex flex-col gap-y-5 ">
            {attachmentsData.map((file: FileData, key: number) => (
              <div key={key} className="flex justify-between items-center">
                <div className="flex gap-3 justify-between items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleRemoveFile(file.id);
                    }}
                  >
                    {loadingIndex == null && <img src="assets/delete.svg" alt="delete" width={12} height={12} />}
                  </div>
                  <Typography fontSize={12}>
                    {file.name.length < 12 ? file.name : file.name.slice(0, 12) + "..."}
                  </Typography>
                </div>
                {!file.isEncrypted ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      !loadingIndex && handleFileEncrypt(file, key);
                    }}
                  >
                    <Typography fontSize={12} className="text-[#5B34F4]">
                      {loadingIndex == key ? "Xooging.." : loadingIndex != null && loadingIndex != key ? "" : "Xoog it"}
                    </Typography>
                  </div>
                ) : (
                  <Typography fontSize={12} color={"green"}>
                    Encrypted
                  </Typography>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center w-full">
            <Typography fontSize={12}>Refresh to update</Typography>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-col gap-6">
        <Typography fontSize={10}>*At present, XooG supports files with a maximum size of 5MB.</Typography>
        {loadingIndex != null && (
          <Typography fontSize={10}>Note: Please retain all attachments while Xooging.</Typography>
        )}
      </div>
    </div>
  );
};
