import { Typography } from "@mui/material";
import React from "react";
import { DisplayMesssageProps } from "../../../types";
import { ProgressBar } from "../../templates";

export const DisplayErrors = ({ errorMessage, Message, loading, value, label }: DisplayMesssageProps) => {
  return (
    <>
      {errorMessage && (
        <Typography align="center" className="text-red-700 pt-7" fontSize={14}>
          {errorMessage}
        </Typography>
      )}
      {Message && loading && !errorMessage && (
        <Typography align="center" className="text-black pt-7" fontSize={14}>
          {Message}
        </Typography>
      )}
      {loading && !errorMessage && (
        <div className="w-full h-24 flex justify-center items-center">
          <ProgressBar value={value} label={label} />
        </div>
      )}
    </>
  );
};
