import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { spinnerProps } from "types";

export const Spinner = ({ size }: spinnerProps) => {
  let spinnerSize;
  switch (size) {
    case "sm":
      spinnerSize = 12;
      break;
    case "md":
      spinnerSize = 48;
      break;
    case "lg":
      spinnerSize = 64;
      break;
    default:
      spinnerSize = 48;
  }

  return (
    <div className="w-full flex justify-center items-center h-48" style={{ height: spinnerSize, width: spinnerSize }}>
      <CircularProgress color="secondary" size={spinnerSize} />
    </div>
  );
};
