import React from "react";
import { Typography } from "@mui/material";
import { pickUpLines } from "../../Utils/constants/";

export const Gettingstarted = () => {
  return (
    <div className="flex flex-col gap-y-5">
      {pickUpLines.map((item, index) => (
        <div className="p-3 bg-white rounded-xl flex gap-x-4 items-center justify-between" key={index}>
          <img src={item.icon} alt="profile picture" />
          <div className="flex flex-col">
            <Typography variant="overline" fontSize="12px">
              {item.heading}
            </Typography>
            <Typography variant="caption" fontSize="11px">
              {item.sub}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};
